import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { ETH } from 'constants/network'
import { formatStringSvgToImageSrc } from 'utils/common'

type AssetItemProps = {
  value: string
  name: string
  symbol: string
  image: string
}

// FIXME: Add dynamic colors depending on the client theme (light/dark)
// FIXME: -> currently Picker component renders dynamically optionRenderer prop so we can't use the context here
export const AssetItem = ({ name, symbol, value, image }: AssetItemProps) => (
  <Flex alignItems="center">
    <Image
      src={image === ETH.image ? `/assets/${ETH.image}.svg` : formatStringSvgToImageSrc(image)}
    />
    <Box ml={3}>
      <Text variant="title2medium">{name}</Text>
      <Text color="grey02" variant="caption2medium">
        {symbol}
      </Text>
    </Box>
    <Text ml="auto" color="grey02" variant="text2regular">
      {value || 0}
    </Text>
  </Flex>
)
