import type { BoxProps } from '@chakra-ui/react'
import { Box, Button, Flex, Image, Text, useColorMode, useMediaQuery } from '@chakra-ui/react'
import Big from 'big.js'
import Decimal from 'decimal.js'
import type { ethers } from 'ethers'
import type { TFunction } from 'i18next'
import type { GetServerSidePropsContext } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { RiArrowRightLine, RiCheckboxCircleFill } from 'react-icons/ri'
import { useAccount } from 'wagmi'
import { AmountInput } from 'components/AmountInput'
import { AmountLabel } from 'components/AmountLabel'
import { BridgeApproveModal } from 'components/BridgeApproveModal'
import { BridgeQuarantinedModal } from 'components/BridgeQuarantinedModal'
import { BridgeSelector } from 'components/BridgeSelector/BridgeSelector'
import { BridgeSuccessModal } from 'components/BridgeSuccessModal'
import { ConnectButton } from 'components/ConnectButton'
import { DepositLimitStates } from 'components/DepositLimitStates'
import { ExternalLink } from 'components/ExternalLink'
import { Label } from 'components/Label'
import { MessageRelayerModal } from 'components/MessageRelayerModal'
import { ProgressBar } from 'components/ProgressBar'
import { SwitchNetworkModal } from 'components/SwitchNetworkModal'
import { Table } from 'components/Table'
import {
  DECIMALS,
  DEFAULT_VALUE,
  EXIT_TRANSACTION_TYPE,
  EXIT_TXN_STATUS_LABELS,
  TRANSACTION_TYPE_LABELS,
  TXN_STATUS_ICONS,
  TXN_STATUS_VARIANTS,
} from 'constants/common'
import {
  CHAINS,
  ETH,
  EXPECTED_ROLL_UP_WITHDRAWAL_TIME_IN_HOURS,
  L1_LAYER_IDS,
} from 'constants/network'
import { Routes } from 'constants/routes'
import { env } from 'env.client'
import type { RecentTransactionsListItem as TxItem } from 'generated/reactQueryClient'
import {
  RecentTransactionsListItemExitStatus as TxItemExitStatus,
  RecentTransactionsListItemStatus as TxItemStatus,
  useGetRecentTransactions,
} from 'generated/reactQueryClient'
import { useBridge } from 'hooks/useBridge'
import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { useGetGasFee } from 'hooks/useGasFee'
import { useHandleCurrentTransaction } from 'hooks/useHandleCurrentTransaction'
import { useHandleQuarantinedTransaction } from 'hooks/useHandleQuarantinedTransaction'
import { useThemeColors } from 'hooks/useThemeColors'
import { BaseLayout } from 'layouts/BaseLayout'
import { useAssetContext } from 'providers/AssetProvider'
import { useFeatureFlagsContext } from 'providers/FeatureFlagsProvider'
import { useDepositLimits } from 'utils/bridge/useDepositLimits'
import { calculateGasFees, getFormattedNumber, weiToEth } from 'utils/common'
import { getTruncatedText } from 'utils/getTruncatedText'
import { serverSideTranslations } from 'utils/i18n'

const MAX_INPUT_CHAR_LENGTH = 20
const WRAPPER_PX_OFFSET = { base: 4, md: 6 }
const WRAPPER_MX_OFFSET = { base: -4, md: -6 }

type GetBridgeButtonProps = {
  amount?: string
  ethBalance?: string
  selectedAssetBalance?: string
  gasFee?: string
  asset: string
  t: TFunction<['common'], undefined>
  isValueGreaterThanDepositLimit: boolean
  bridgeDepositLimitPercentage: number
  from: {
    id: number
    label: string
  }
}

const getBridgeButtonProps = ({
  amount = DEFAULT_VALUE,
  ethBalance = DEFAULT_VALUE,
  selectedAssetBalance = DEFAULT_VALUE,
  gasFee = DEFAULT_VALUE,
  asset,
  t,
  isValueGreaterThanDepositLimit,
  bridgeDepositLimitPercentage,
  from,
}: GetBridgeButtonProps) => {
  if (
    (from.id === CHAINS.l1.id && bridgeDepositLimitPercentage === 0) ||
    (from.id === CHAINS.l1.id && bridgeDepositLimitPercentage > 0 && isValueGreaterThanDepositLimit)
  ) {
    return { isDisabled: true, label: t('common:Bridge') }
  }

  if (!amount) {
    return { isDisabled: true, label: t('common:EnterAmountToBridge') }
  }

  const gasFeeD = new Decimal(gasFee)
  const amountD = new Decimal(amount)
  const ethBalanceD = new Decimal(ethBalance)
  // *: selectedAssetBalance is a locale string with commas (e.g. "1,234,567.89")
  const selectedAssetBalanceD = new Decimal(selectedAssetBalance.replaceAll(',', ''))

  if (amountD.greaterThan(selectedAssetBalanceD) || amountD.lessThan(0)) {
    return { isDisabled: true, label: t('common:NotEnoughFunds') }
  }

  if (
    (asset === ETH.symbol && amountD.plus(gasFeeD).greaterThan(selectedAssetBalanceD)) ||
    (asset !== ETH.symbol && gasFeeD.greaterThan(ethBalanceD))
  ) {
    return { isDisabled: true, label: t('common:NotEnoughGas', { gasValue: gasFee }) }
  }

  return { isDisabled: false, label: t('common:Bridge') }
}

const COMMON_BUTTON_PROPS = {
  size: 'big',
  width: '100%',
  rightIcon: <RiArrowRightLine style={{ marginLeft: '16px' }} />,
}

const Wrapper = ({ children, ...props }: { children: React.ReactNode } & BoxProps) => (
  <Box pt={7} px={WRAPPER_PX_OFFSET} borderRadius={10} {...props}>
    {children}
  </Box>
)

const Checkbox = ({ label }: { label: string }) => {
  const COLORS = useThemeColors()

  return (
    <Flex alignItems="center" mt={3}>
      <RiCheckboxCircleFill color={COLORS.zircuitPrimary} size={24} />
      <Text ml={2} variant="text2medium">
        {label}
      </Text>
    </Flex>
  )
}

const RECENT_TRANSACTIONS_LIMIT = 3

const Bridge = () => {
  const COLORS = useThemeColors()
  const { colorMode } = useColorMode()
  const { t } = useTranslation(['common'])
  const { isDepositLimitEnabled } = useFeatureFlagsContext()
  const { address, chain: activeChain, isConnected } = useAccount()
  const router = useRouter()
  const { selectedAsset, setSelectedAsset, ethBalance, selectedAssetBalance, assets } =
    useAssetContext()
  const {
    isAddressDepositCreditLoading,
    isAddressDepositLimitLoading,
    isBridgeDepositCreditAndLimitLoading,
    bridgeDepositLimitPercentage,
    bridgeDepositCredit,
    bridgeDepositLimit,
    addressDepositCredit,
    addressDepositLimit,
    refetchDepositLimits,
  } = useDepositLimits()

  const [isDepositLimitWarningAlertOpen, setIsDepositLimitWarningAlertOpen] =
    React.useState<boolean>(false)
  const [isSwitchNetworkModalOpen, setIsSwitchNetworkModalOpen] = React.useState<boolean>(false)
  const [isBridgeApproveModalOpen, setIsBridgeApproveModalOpen] = React.useState<boolean>(false)
  const [isBridgeQuarantinedModalOpen, setIsBridgeQuarantinedModalOpen] =
    React.useState<boolean>(false)
  const [internalBridgedTransactionData, setInternalBridgedTransactionData] =
    React.useState<TxItem | null>(null)
  const [bridgedTransaction, setBridgedTransaction] =
    React.useState<ethers.providers.TransactionResponse | null>(null)
  const [pendingTransaction, setPendingTransaction] =
    React.useState<ethers.providers.TransactionResponse | null>(null)

  const [from, setFrom] = React.useState<{
    id: number
    label: string
  }>({ id: CHAINS.l1.id, label: CHAINS.l1.name })
  const [to, setTo] = React.useState<{
    id: number
    label: string
  }>({ id: CHAINS.zircuit.id, label: CHAINS.zircuit.name })

  const [amount, setAmount] = React.useState<string>('')
  const [selectedTransactionL2Hash, setSelectedTransactionL2Hash] = React.useState<string | null>(
    null,
  )
  const [isExecuted, setIsExecuted] = React.useState<boolean>(false)
  const [txInExecution, setTxInExecution] = React.useState<string | null>(null)

  const {
    data: recentTransactions,
    isLoading: areRecentTransactionsLoading,
    refetch,
  } = useGetRecentTransactions({
    limit: RECENT_TRANSACTIONS_LIMIT,
    txType: ['l1l2', 'l2l1'],
    ...(address && { address }),
  })

  useHandleCurrentTransaction({
    transactions: recentTransactions,
    refetch,
    bridgedTransaction,
    setInternalBridgedTransactionData,
    from,
  })

  const selectedTransaction = React.useMemo(
    () => recentTransactions?.data?.find((tx) => tx.l2TxHash === selectedTransactionL2Hash) ?? null,
    [recentTransactions?.data, selectedTransactionL2Hash],
  )

  const { currentQuarantinedTransactionData, isPendingTransactionQuarantined } =
    useHandleQuarantinedTransaction({
      pendingTransaction,
      setIsBridgeApproveModalOpen,
      setIsBridgeQuarantinedModalOpen,
    })

  const { handleBridge } = useBridge({
    amount: new Big(amount || '0').toFixed(), // Get normal number notation -> https://mikemcl.github.io/big.js/#toF
    fromId: from.id,
    setBridgedTransaction,
    setPendingTransaction,
    setIsSwitchNetworkModalOpen,
    isPendingTransactionQuarantined,
  })

  const debouncedValue = useDebouncedValue(amount)
  const {
    data: gasFee = DEFAULT_VALUE,
    isFetching: isGasFeeLoading,
    refetch: refetchGasFee,
  } = useGetGasFee({
    amountToTransfer: debouncedValue,
    chainId: from.id,
  })

  const isValueGreaterThanDepositLimit = isDepositLimitEnabled
    ? new Big(amount || '0').gt(addressDepositCredit) && selectedAsset.symbol === ETH.symbol
    : false

  const bridgeButtonProps = getBridgeButtonProps({
    amount,
    ethBalance,
    selectedAssetBalance,
    gasFee,
    asset: selectedAsset.symbol,
    t,
    isValueGreaterThanDepositLimit,
    bridgeDepositLimitPercentage,
    from,
  })

  const bridgeTitle =
    from.id === CHAINS.zircuit.id ? t('common:BridgeFromZircuit') : t('common:BridgeToZircuit')

  const handleActionButton = (row: TxItem) => {
    setIsBridgeApproveModalOpen(true)

    setAmount(
      getFormattedNumber(
        weiToEth(row.transfers?.[0]?.wei ?? row.value, row.transfers?.[0]?.decimals ?? DECIMALS),
      ),
    )

    const assetToUse = assets.find(
      (asset) => asset.symbol === row.transfers?.[0]?.symbol || ETH.symbol,
    )

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setSelectedAsset(assetToUse!)

    if (row.txType === EXIT_TRANSACTION_TYPE) {
      setFrom({ id: CHAINS.zircuit.id, label: CHAINS.zircuit.name })
      setTo({ id: CHAINS.l1.id, label: CHAINS.l1.name })
    }
  }

  const [isMobile] = useMediaQuery('(max-width: 863px)')

  const doesValueExceedDepositLimit = isDepositLimitEnabled
    ? Boolean(amount.length) && new Big(amount).gt(bridgeDepositCredit)
    : false

  return (
    <>
      <Head>
        <title>{t('common:pageName.Bridge')}</title>
      </Head>
      <Flex flexDir="column" alignItems="center">
        <Flex maxW={{ base: '100%', md: 895 }} w="100%" mb={4} gap={2} justifyContent="center">
          <Wrapper
            justifyContent="center"
            justifySelf="center"
            w={335}
            bgImage={`/assets/portal_${colorMode}.png`}
            backgroundSize="cover"
            display={{ base: 'none', md: 'block' }}
          >
            <Text w={200} variant="heading4medium">
              {t('common:portalTitle')}
            </Text>
            <Checkbox label={t('common:BulletproofBridge')} />
            <Checkbox label={t('common:PerformanceDriven')} />
            <Checkbox label={t('common:AccessTo100DApps')} />
          </Wrapper>
          <Wrapper maxW={600} w="100%" bg={COLORS.bgSecondary}>
            <Text variant="heading4medium">{bridgeTitle}</Text>
            <BridgeSelector mt={6} from={from} to={to} onFromChange={setFrom} onToChange={setTo} />
            <AmountInput
              mt={{ base: 4, md: 6 }}
              isGasFeeLoading={isGasFeeLoading}
              value={amount}
              from={from}
              isValueGreaterThanDepositLimit={isValueGreaterThanDepositLimit}
              doesValueExceedDepositLimit={doesValueExceedDepositLimit}
              onValueChange={(value) => {
                if (value.length <= MAX_INPUT_CHAR_LENGTH) {
                  setAmount(value)
                }
              }}
              onMaxPressed={(value) => {
                const asyncFn = async () => {
                  if (selectedAsset.symbol === ETH.symbol) {
                    const maxValue = new Decimal(selectedAssetBalance).sub(gasFee)

                    setAmount(maxValue.isNegative() ? value : maxValue.toString())
                  } else {
                    setAmount(value)
                  }
                  await refetchGasFee()
                }

                void asyncFn()
              }}
              isDisabled={
                from.id === CHAINS.l1.id && isConnected && bridgeDepositLimitPercentage === 0
              }
            />
            {isDepositLimitEnabled && (
              <DepositLimitStates
                from={from}
                isWarningAlertOpen={isDepositLimitWarningAlertOpen}
                setIsWarningAlertOpen={setIsDepositLimitWarningAlertOpen}
                isValueGreaterThanDepositLimit={isValueGreaterThanDepositLimit}
                doesValueExceedDepositLimit={doesValueExceedDepositLimit}
                bridgeDepositLimitPercentage={bridgeDepositLimitPercentage}
                isAddressDepositCreditLoading={isAddressDepositCreditLoading}
                isAddressDepositLimitLoading={isAddressDepositLimitLoading}
                isBridgeDepositCreditAndLimitLoading={isBridgeDepositCreditAndLimitLoading}
                bridgeDepositCredit={bridgeDepositCredit}
                bridgeDepositLimit={bridgeDepositLimit}
                addressDepositCredit={addressDepositCredit}
                addressDepositLimit={addressDepositLimit}
              />
            )}
            <Box
              mt={
                (isDepositLimitWarningAlertOpen &&
                  !isValueGreaterThanDepositLimit &&
                  !doesValueExceedDepositLimit) ||
                (bridgeDepositLimitPercentage === 0 && !doesValueExceedDepositLimit)
                  ? 2
                  : 8
              }
              mx={WRAPPER_MX_OFFSET}
              px={WRAPPER_PX_OFFSET}
              border="1px solid"
              borderColor={COLORS.grey06}
              bgColor={COLORS.bgPrimary}
              borderRadius={10}
            >
              <AmountLabel
                mt={8}
                label={t('common:YouAreMoving')}
                amount={amount}
                asset={selectedAsset.symbol}
              />
              <AmountLabel
                mt={3}
                label={t('common:EstimatedGasFees')}
                amount={gasFee}
                asset={ETH.symbol}
                isValueLoading={isGasFeeLoading}
              />
              <Box mt={4} w="100%" h="1px" bgColor={COLORS.grey06} />
              <AmountLabel
                mt={6}
                labelColor={COLORS.dark01}
                textVariant="title2medium"
                label={t('common:TotalAmount')}
                amount={
                  selectedAsset.symbol === ETH.symbol
                    ? String(new Decimal(Number(amount)).plus(new Decimal(Number(gasFee))))
                    : amount
                }
                asset={selectedAsset.symbol}
              />
              <Box mb={6} mt={9}>
                {isConnected ? (
                  <Button
                    {...COMMON_BUTTON_PROPS}
                    isDisabled={
                      bridgeButtonProps.isDisabled ||
                      !amount ||
                      isGasFeeLoading ||
                      doesValueExceedDepositLimit
                    }
                    onClick={() => {
                      if (activeChain?.id === from.id) {
                        setIsBridgeApproveModalOpen(true)
                      } else {
                        setIsSwitchNetworkModalOpen(true)
                      }
                    }}
                  >
                    {bridgeButtonProps.label}
                  </Button>
                ) : (
                  <ConnectButton {...COMMON_BUTTON_PROPS} />
                )}
              </Box>
            </Box>
          </Wrapper>
        </Flex>
        {isConnected ? (
          <Table
            maxW={{ base: 600, md: 895 }}
            w="100%"
            data={recentTransactions?.data ?? []}
            hasPagination={false}
            title="Recent transactions"
            isLoading={areRecentTransactionsLoading}
            loaderHeight="14.875rem"
            rightHeaderContent={
              <Text
                onClick={async () => {
                  await router.push(Routes.transactions())
                }}
                color={COLORS.zircuitPrimary}
                variant="text3medium"
                _hover={{ cursor: 'pointer' }}
              >
                {t('common:SeeAllTransactions')}
              </Text>
            }
            {...(isMobile
              ? {
                  renderMobile: (row: TxItem) => (
                    <Flex
                      key={row.l2TxHash}
                      p={4}
                      flexDir="column"
                      border="1px solid"
                      borderColor={COLORS.grey06}
                      borderRadius={10}
                      gap={4}
                      display={{ base: 'flex', md: 'none' }}
                      _notLast={{ mb: 3 }}
                    >
                      <Flex alignItems="center" justifyContent="space-between">
                        <Label
                          variant={TXN_STATUS_VARIANTS[row.status]}
                          icon={TXN_STATUS_ICONS[row.status]}
                        >
                          {row.status}
                        </Label>
                      </Flex>
                      <ProgressBar
                        status={row.txType === EXIT_TRANSACTION_TYPE ? row.exitStatus : row.status}
                      />
                      <Flex justifyContent="space-between">
                        <Box>
                          <Text mb={1.5} variant="caption2regular" color={COLORS.grey03}>
                            {t('common:transactionsTable.Type')}
                          </Text>
                          <Text variant="text2medium">
                            {
                              TRANSACTION_TYPE_LABELS[
                                row?.txType?.toLowerCase() as keyof typeof TRANSACTION_TYPE_LABELS
                              ]
                            }
                          </Text>
                          <ExternalLink
                            variant="secondary"
                            {...(row.txType === EXIT_TRANSACTION_TYPE &&
                            ![TxItemExitStatus.waiting, TxItemExitStatus.ready_to_prove].some(
                              (status) => row.exitStatus === status,
                            )
                              ? {
                                  href: `${env.NEXT_PUBLIC_L1_URL}/tx/${row.l1TxHash}`,
                                  label: getTruncatedText(row?.l1TxHash ?? ''),
                                }
                              : {
                                  href: `${env.NEXT_PUBLIC_BLOCK_EXPLORER_URL}/tx/${row.l2TxHash}`,
                                  label: getTruncatedText(row?.l2TxHash ?? ''),
                                })}
                          />
                        </Box>
                        <Box>
                          <Text mb={1.5} variant="caption2regular" color={COLORS.grey03}>
                            {t('common:transactionsTable.Amount')}
                          </Text>
                          <Text variant="text2medium">
                            {getFormattedNumber(
                              weiToEth(
                                row.transfers?.[0]?.wei ?? row.value,
                                row.transfers?.[0]?.decimals ?? DECIMALS,
                              ),
                            )}{' '}
                            {row.transfers?.[0]?.symbol ?? ETH.symbol}
                          </Text>
                        </Box>
                      </Flex>
                      {[
                        TxItemExitStatus.ready_to_relay,
                        TxItemExitStatus.ready_to_prove,
                        TxItemExitStatus.in_challenge_period,
                        TxItemExitStatus.waiting,
                      ].includes(row.exitStatus) ? (
                        <Button
                          isLoading={txInExecution === row.l2TxHash}
                          isDisabled={
                            row.exitStatus === TxItemExitStatus.waiting ||
                            row.exitStatus === TxItemExitStatus.in_challenge_period ||
                            row.status === TxItemStatus.pending
                          }
                          onClick={() => {
                            setIsExecuted(false)
                            if (row.l2TxHash) {
                              setSelectedTransactionL2Hash(row.l2TxHash)
                            }
                          }}
                        >
                          {row.exitStatus === TxItemExitStatus.waiting
                            ? `${t(
                                'common:transactionsTable.WaitUpTo',
                              )} ${t('common:hours', { count: EXPECTED_ROLL_UP_WITHDRAWAL_TIME_IN_HOURS })}`
                            : EXIT_TXN_STATUS_LABELS[row.exitStatus]}
                        </Button>
                      ) : (
                        <Button
                          variant="tertiary"
                          onClick={() => {
                            handleActionButton(row)
                          }}
                        >
                          {t('common:BridgeAgain')}
                        </Button>
                      )}
                    </Flex>
                  ),
                }
              : {
                  renderTableRow: (row: TxItem) => (
                    <Table.Row key={row.l2TxHash}>
                      <Table.RowCell>
                        <Label
                          variant={TXN_STATUS_VARIANTS[row.status]}
                          icon={TXN_STATUS_ICONS[row.status]}
                        >
                          {row.status}
                        </Label>
                      </Table.RowCell>
                      <Table.RowCell>
                        <Box isTruncated>
                          <Text variant="title2medium" mb={1.5}>
                            {
                              TRANSACTION_TYPE_LABELS[
                                row?.txType?.toLowerCase() as keyof typeof TRANSACTION_TYPE_LABELS
                              ]
                            }
                          </Text>
                          <ExternalLink
                            variant="secondary"
                            {...(row.txType === EXIT_TRANSACTION_TYPE &&
                            ![TxItemExitStatus.waiting, TxItemExitStatus.ready_to_prove].some(
                              (status) => row.exitStatus === status,
                            )
                              ? {
                                  href: `${env.NEXT_PUBLIC_L1_URL}/tx/${row.l1TxHash}`,
                                  label: getTruncatedText(row?.l1TxHash ?? ''),
                                }
                              : {
                                  href: `${env.NEXT_PUBLIC_BLOCK_EXPLORER_URL}/tx/${row.l2TxHash}`,
                                  label: getTruncatedText(row?.l2TxHash ?? ''),
                                })}
                          />
                        </Box>
                      </Table.RowCell>
                      <Table.RowCell>
                        <Text variant="text2medium">
                          {getFormattedNumber(
                            weiToEth(
                              row.transfers?.[0]?.wei ?? row.value,
                              row.transfers?.[0]?.decimals ?? DECIMALS,
                            ),
                          )}{' '}
                          {row.transfers?.[0]?.symbol ?? ETH.symbol}
                        </Text>
                      </Table.RowCell>
                      <Table.RowCell>
                        <ProgressBar
                          status={
                            row.txType === EXIT_TRANSACTION_TYPE ? row.exitStatus : row.status
                          }
                        />
                      </Table.RowCell>
                      <Table.RowCell>
                        {[
                          TxItemExitStatus.ready_to_relay,
                          TxItemExitStatus.ready_to_prove,
                          TxItemExitStatus.in_challenge_period,
                          TxItemExitStatus.waiting,
                        ].includes(row.exitStatus) ? (
                          <Button
                            isLoading={txInExecution === row.l2TxHash}
                            isDisabled={
                              row.exitStatus === TxItemExitStatus.waiting ||
                              row.exitStatus === TxItemExitStatus.in_challenge_period ||
                              row.status === TxItemStatus.pending
                            }
                            onClick={() => {
                              setIsExecuted(false)
                              if (row.l2TxHash) {
                                setSelectedTransactionL2Hash(row.l2TxHash)
                              }
                            }}
                          >
                            {row.exitStatus === TxItemExitStatus.waiting
                              ? `${t(
                                  'common:transactionsTable.WaitUpTo',
                                )} ${t('common:hours', { count: EXPECTED_ROLL_UP_WITHDRAWAL_TIME_IN_HOURS })}`
                              : EXIT_TXN_STATUS_LABELS[row.exitStatus]}
                          </Button>
                        ) : (
                          <Button
                            variant="tertiary"
                            onClick={() => {
                              handleActionButton(row)
                            }}
                          >
                            {t('common:BridgeAgain')}
                          </Button>
                        )}
                      </Table.RowCell>
                    </Table.Row>
                  ),
                })}
          />
        ) : (
          <Flex
            border="1px solid"
            borderColor={COLORS.grey06}
            maxW={{ base: 500, md: 850 }}
            w="100%"
            mt={10}
            pt={0.5}
            pb={3.5}
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            borderRadius={10}
          >
            <Image src={`/assets/not_connected_${colorMode}.svg`} />
            <Text variant={{ base: 'title1medium', md: 'heading4medium' }} mb={2}>
              {t('common:ConnectWalletToAccessRecentTransactions')}
            </Text>
            <Text variant={{ base: 'text3regular', md: 'text2regular' }} color={COLORS.grey02}>
              {t('common:WeNeedYourWalletAddressToCollectData')}
            </Text>
          </Flex>
        )}
      </Flex>
      <SwitchNetworkModal
        isOpen={isSwitchNetworkModalOpen}
        targetNetwork={from}
        onSwitch={() => {
          setIsBridgeApproveModalOpen(true)
        }}
        onClose={() => {
          setIsSwitchNetworkModalOpen(false)
        }}
        title={t('common:SwitchToNetwork', { networkName: from.label })}
      />
      <BridgeApproveModal
        isOpen={isBridgeApproveModalOpen}
        amount={amount}
        from={from}
        to={to}
        gasFee={gasFee}
        isGasFeeLoading={isGasFeeLoading}
        title={bridgeTitle}
        isPendingTransactionQuarantined={isPendingTransactionQuarantined}
        onApprove={async () => {
          await handleBridge()
          setIsBridgeApproveModalOpen(false)
        }}
        onClose={() => {
          setIsBridgeApproveModalOpen(false)
        }}
      />
      <BridgeSuccessModal
        isOpen={Boolean(bridgedTransaction)}
        onClick={async () => {
          await router.push(Routes.transactions())
        }}
        gasFee={calculateGasFees({
          gasLimit: Number(bridgedTransaction?.gasLimit),
          gasPrice: Number(bridgedTransaction?.gasPrice),
        })}
        onClose={() => {
          void refetch()
          if (isDepositLimitEnabled) {
            void refetchDepositLimits()
          }
          setAmount('')
          setBridgedTransaction(null)
        }}
        received={amount}
        txType={L1_LAYER_IDS.includes(from.id) ? 'deposit' : 'withdrawal'}
        bridgedTransactionData={internalBridgedTransactionData}
      />
      <BridgeQuarantinedModal
        isOpen={isBridgeQuarantinedModalOpen}
        onClose={() => {
          setIsBridgeQuarantinedModalOpen(false)
          setPendingTransaction(null)
        }}
        reason={currentQuarantinedTransactionData?.quarantinedReason ?? ''}
      />
      <MessageRelayerModal
        data={selectedTransaction}
        isConnected={isConnected}
        isExecuted={isExecuted}
        onClearData={() => {
          setSelectedTransactionL2Hash(null)
        }}
        onExecutionChange={(tx) => {
          setTxInExecution(tx)
        }}
        onIncorrectNetwork={() => {
          setIsSwitchNetworkModalOpen(true)
        }}
        onSuccessBridge={() => {
          setIsExecuted(true)
        }}
        refetch={refetch}
        txInExecution={txInExecution}
      />
    </>
  )
}

Bridge.getLayout = (page: React.ReactNode) => <BaseLayout>{page}</BaseLayout>

export const getServerSideProps = async (context: GetServerSidePropsContext) => ({
  props: { ...(await serverSideTranslations(context, ['common'])) },
})

export default Bridge
